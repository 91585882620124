import React, { useCallback, useEffect } from 'react'
import { utils } from '../../utils/utils'
import { useLocation } from 'react-router-dom'
import { format, isValid, parse } from 'date-fns'

export const ScheduleListTable = ({list, formData, setFormData, handleInputChange}) => {

  const {pathname} = useLocation();

  const isTimeBefore = (row) => {
    return utils.isTimeBefore(row.schedule, pathname);
  }


  const isSelected = (row) => {
    if (isTimeBefore(row)) {
      return row?.schedule.movieTime.scheduleStart === formData?.schedule?.movieTime?.scheduleStart
    }

    return false
  }

  const handleMovieTableRadioChange = useCallback((row) => {
    const {schedule, screenCode} = row
    if(schedule?.movieTime?.scheduleStart === formData?.schedule?.movieTime?.scheduleStart) return

    if (isTimeBefore(row)) {
      handleInputChange(
        {
          target:
            [
              {name: 'schedule', value: JSON.stringify(schedule)},
              {name: 'screen', value: screenCode}
            ]
        }
      ) // 핸들러 호출

      if (schedule.movieTime.scheduleStart !== formData?.schedule?.movieTime?.scheduleStart) {
        setFormData((prev) => ({
          ...prev,
          imgUrl: schedule.movie.posterThumbnailUrl || ''
        }))
      }
    }
  }, [formData?.schedule])

  const cell = [
    {title: '영화명', value: 'schedule.movie.title'},
    {title: '등급', value: 'schedule.movie.rating'},
    {title: '상영일자', value: 'schedule.movieTime.scheduleDate'},
    {title: '상영관명', value: 'schedule.screenName'},
    {title: '시작시간', value: 'schedule.movieTime.scheduleStart'},
    {title: '상영타입', value: 'schedule.movie.showType'},
    {title: '상영포맷', value: 'schedule.movie.showTypeFormat'},
    {title: '총 좌석수', value: 'totalSeatCount'},
    {title: '남은 좌석수', value: 'availSeatCount'},
    // {title: '보류 좌석수', value: 'holdSeatCount'},
  ]

  const fixInvalidDateString = (dateString) => {
    // 24시를 00시로 변경
    return dateString.replace(' 24:', ' 00:');
  };

  const getValueFromPath = (obj, path) => {
    return path?.split('.').reduce((acc, part) => {
      if (acc && acc[part] !== undefined && acc[part] !== null) {
        // showTypeFormat과 showType에 대한 특별한 처리
        if(part === 'rating') {
          return utils.renderScheduleRatingType(acc[part])
        }
        if (part === 'showType') {
          return utils.showTypeCodeToLabel(acc[part]);
        }
        if (part === 'showTypeFormat' ) {
          return utils.showTypeFormatCodeToLabel(acc[part]);
        }
        if (part === 'scheduleStart') {
          const rawDate = acc[part];
          const fixedDate = fixInvalidDateString(rawDate); // 24시 문제 수정
          const dateValue = parse(fixedDate, 'yyyy-MM-dd HH:mm:ss', new Date());

          if (isValid(dateValue)) {
            return format(dateValue, 'HH:mm'); // 유효한 Date 객체만 처리
          } else {
            console.error(`Invalid date value: ${acc[part]}`);
            return 'Invalid Date';
          }
        }
        return acc[part]; // 다른 경우엔 그냥 값을 반환
      }
      return undefined; // acc가 없으면 undefined 반환
    }, obj);
  };


  const getClass = (row) => {
    const backgroundClass = isSelected(row)
      ? 'bg-gray-100'
      : isTimeBefore(row)
        ? 'bg-white cursor-pointer group-hover:bg-gray-50 hover:bg-gray-50 transition disabled:bg-gray-200'
        : 'bg-gray-200 cursor-default disabled:bg-gray-200';

    const cursorClass = isTimeBefore(row) ? 'cursor-pointer' : 'cursor-default';

    return `${backgroundClass} ${cursorClass}`
  }

  useEffect(() => {
    const checkedRadioInput = document.querySelector('input[name="movie"]:checked')

    if (!formData?.schedule?.id && checkedRadioInput) {
      checkedRadioInput.checked = false // 체크된 라디오 버튼의 값
    }
  }, [formData?.schedule])

  return (
    <table className="min-w-full divide-y-2 divide-gray-200 bg-white text-sm">
      <thead className="ltr:text-left rtl:text-right text-center bg-gray-50">
      <tr className={'text-xs'}>
        <th className="sticky inset-y-0 start-0 bg-gray-50 px-2.5 py-2">
        </th>

        <th className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
          회차
        </th>

        {cell.map((r, id) => (
          <th key={r.title} className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">{r.title}</th>
        ))}
      </tr>
      </thead>

      <tbody className="divide-y divide-gray-200">
      {list?.map((row, idx) => (
        <tr className={`text-center ${getClass(row)} group`}
            key={`${row.schedule.id}`}
            onClick={() => handleMovieTableRadioChange(row)}
        >
          <td className={`sticky inset-y-0 start-0 px-2.5 py-2 ${getClass(row)}`}>
              <label className="sr-only" htmlFor="list">list</label>

              <input
                className={`text-black border-gray-300  focus:ring-black focus:ring-1 size-4`}
                type="radio"
                id="list"
                required
                name="schedule"
                value={JSON.stringify(row)}
                readOnly={true}
                disabled={!isTimeBefore(row)}
                checked={isSelected(row)}
              />
          </td>
          <td className={`text-xs whitespace-nowrap px-4 py-2 ${isTimeBefore(row) ? 'text-gray-800' : 'text-gray-400'}`}>
            {idx +1}
          </td>
          {cell.map((r, id) => (
            <td key={r.title} className={`text-xs whitespace-nowrap px-4 py-2 ${isTimeBefore(row) ? 'text-gray-800' : 'text-gray-400'}`}
            >
              {getValueFromPath(row, r.value)}
            </td>
          ))}
        </tr>
      ))}
      </tbody>
    </table>
  )
}