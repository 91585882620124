import React, { Fragment, useEffect, useState } from 'react'
import StyledContainer from '../../../components/StyledContainer'
import { SpinLoading } from '../../../assets/SpinLoading'
import { utils } from '../../../utils/utils'

export const Seat = ({list, loading, seatMatrix}) => {
  const [seatTypeList, setSeatTypeList] = useState([])
  const [usedCouponSeat, setUsedCouponSeat] = useState([])

  const getSeatStyle = (seat) => {
    return {
      backgroundColor: utils.seatColor(seat),
      border: seat?.isCouponSeat ? '2px solid red' : null,
      // padding: '8px',
      margin: '1px',
      textAlign: 'center',
      fontSize: '10px',
      color: 'white',
      flex: '0 0 24px',
      width: '24px',
      height: '24px'
    }
  }

  const groupSeat = (seat) => {
    const combinedArray = []

    seat.flat().forEach(cur => {
      const type = cur?.SeatType
      const status = cur?.SeatStatus

      // type과 status가 존재하고 각각 중복이 아닐 경우 추가
      if (type && !combinedArray.includes(type)) {
        combinedArray.push(type)
      }

      if (status && !combinedArray.includes(status)) {
        combinedArray.push(status)
      }
    })

    const sortedSeatTypes = combinedArray.sort((a, b) => {
      const orderA = Object.values(utils.SEAT_COLOR_TYPE).find(item => item.value === a)?.order || Infinity
      const orderB = Object.values(utils.SEAT_COLOR_TYPE).find(item => item.value === b)?.order || Infinity
      return orderA - orderB
    })

    setSeatTypeList(sortedSeatTypes)
  }

  const seatClick = () => {
    return alert('좌석은 랜덤으로 배정되며 선택할 수 없습니다.')
  }

  useEffect(() => {
    if (seatMatrix.flat().length > 0) {
      const seat = seatMatrix.flat().filter(r => !!r)
      groupSeat(seat)
      setUsedCouponSeat(seat)
    }
  }, [seatMatrix])

  return (
    <StyledContainer>
      <div className="flex items-center justify-between">
        <div className="text-sm font-medium text-gray-700">
          좌석 <span className={'text-xs text-gray-500'}>(랜덤 배정)</span>
        </div>
        {!loading && list?.length > 0 &&
          <div>
            <p className="flex justify-end gap-1 text-xs font-medium text-gray-500 mb-1">
              <span className={'text-gray-700'}>남은 좌석 {list[0]?.availSeatCount}</span>
              <span>/</span>
              <span>총 좌석 {list[0]?.totalSeatCount}</span>
            </p>
            <p className="flex justify-end gap-1 text-xs font-medium text-gray-400">
              <span>판매 좌석 {usedCouponSeat?.filter(r => r.SeatStatus === 'PAID' && !r.isCouponSeat)?.length}</span>
              <span>/</span>
              <span>쿠폰 좌석 {usedCouponSeat?.filter(r => r.isCouponSeat === true)?.length}</span>
            </p>
          </div>
        }
      </div>

      {loading ?
        <div
          className="flex items-center justify-center w-full h-36">
          <SpinLoading width={'w-8'} height={'h-8'} textColor={'text-gray-500'}/>
        </div>
        :
        <>
          <div className="mt-5 flex justify-center items-center w-full bg-gray-50 px-4 py-2 rounded">
            <div className="p-2 overflow-auto">
              {seatMatrix.map((row, rowIndex) => (
                <div key={rowIndex} className={'flex'}>
                  {row.map((seat, colIndex) => (
                    <div key={colIndex} style={getSeatStyle(seat)} className={'flex justify-center items-center rounded-sm'} onClick={() => seatClick()}>
                      {seat ? `${seat.SeatGroup}${seat.SeatNo}` : ''}
                    </div>
                  ))}
                </div>
              ))}
            </div>
          </div>

          <div className="text-xs font-medium text-gray-700 flex items-center justify-center mt-6 gap-4">
            {seatTypeList?.map((seat, idx) => (
              <Fragment key={seat}>
                {utils.SEAT_COLOR_TYPE[seat.toUpperCase()]?.label &&
                  <p className={'flex items-center'}>
                    <span className={`inline-block mr-1 w-2.5 h-2.5`} style={{background: utils.SEAT_COLOR_TYPE[seat.toUpperCase()]?.color}}/>
                    {utils.SEAT_COLOR_TYPE[seat.toUpperCase()]?.label}
                  </p>
                }
              </Fragment>
            ))}
            {seatMatrix.flat().some(r => r?.isCouponSeat) &&
              <p className={'flex items-center'}>
                <span className={`inline-block mr-1 w-2.5 h-2.5 bg-white border-2 border-red-300`}/>
                쿠폰사용좌석
              </p>
            }
          </div>
        </>
      }
    </StyledContainer>
  )
}