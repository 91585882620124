import React, { useEffect, useState } from 'react'
import { registerLocale } from 'react-datepicker'
import ko from 'date-fns/locale/ko'
import { format } from 'date-fns'
import { SpinLoading } from '../../assets/SpinLoading'
import { Seat } from './components/Seat'
import { ScheduleTable } from '../../components/schedule/ScheduleTable'
import { Coupon } from './components/Coupon'
import { Gender } from './components/Gender'
import { Name } from './components/Name'
import { Contact } from './components/Contact'
import { Footer } from '../../components/Footer'
import { Header } from '../../components/Header'
import { SubmitButton } from './components/SubmitButton'
import { table } from '../../api/airTableAPi'
import { utils } from '../../utils/utils'
import { slack } from '../../api/slackNotifyApi'
import { SelectMovie } from './components/selectProduct/SelectMovie'
import { Helmet } from 'react-helmet-async'
import { useLocation } from 'react-router-dom'
import { seatInfo } from '../../assets/seatLayout'
import api from '../../api/api'

export const Reservation = () => {
  registerLocale('ko', ko) //날짜 한국어로 표시

  const [seatMatrix, setSeatMatrix] = useState([])
  const [list, setList] = useState([])
  const [loading, setLoading] = useState(false)
  const [submitLoading, setSubmitLoading] = useState(false)
  const [formData, setFormData] = useState({
    gender: '',
    name: '',
    phoneNumber: '',
    couponCode: [{id: null, code: null, status: null, basicPackage: null}],
    date: new Date(),
    screen: '',
    imgUrl: '',
    schedule: {}
  })
  const {pathname} = useLocation()

  const getListApi = () => {
    setFormData(prev => ({
      ...prev,
      schedule: {}
    }))

    const {date} = formData


    if (date) {
      const formatDate = format(new Date(date), 'yyyy-MM-dd')
      const payload = {
        theaterCode: 'm019',
        from: formatDate,
        to: formatDate,
        canSaleOnline: true
      }

      api.show.reserveAvailableSchedule(payload).then(({data}) => {
        const scheduleList = data.map(r => r.scheduleList).flat()
        const sort = scheduleList.sort((a, b) => new Date(a.schedule.movieTime.scheduleStart) - new Date(b.schedule.movieTime.scheduleStart))
        setList(sort)
      })
    }
  }

  // 쿠폰 사용한 좌석 (무료, 유료 구분하기 위함)
  const getUsedCouponSeatListApi = async () => {
    const {schedule: {id, movieTime}} = formData

    if (id) {
      try {
        const date = utils.formatDateTime(movieTime.scheduleStart)
        const {records} = await table.usedCouponSeatCheck(date)

        const result = records.map(item => {
          // item?.fields?.Seat가 존재하고 비어있지 않다면
          if (!item?.fields?.Seat) return []

          return item.fields.Seat.split(',').map(seat => seat.trim())
        }).flat()

        return result
      } catch (err) {
        console.error('Error get usedCouponSeat:', err)
      }
    }
  }

  // 좌석 리스트
  const getSeatListApi = async (couponSeatList) => {
    const {schedule: {id}} = formData

    if (id) {
      try {
        const {data: seatStatus} = await api.booking.getSeatsStatus(formData.schedule.id)

        let sortedSeatStatus = Object.keys(seatStatus)
        .sort((a, b) => {
          // 알파벳과 숫자를 분리
          const [letterA, numberA] = a.match(/([A-Za-z]+)(\d+)/).slice(1, 3)
          const [letterB, numberB] = b.match(/([A-Za-z]+)(\d+)/).slice(1, 3)

          // 알파벳 비교 -> 같으면 숫자 비교
          return letterA.localeCompare(letterB) || Number(numberA) - Number(numberB)
        })
        .reduce((acc, key) => {
          acc[key] = seatStatus[key]
          return acc
        }, {})

        const seatLayout = seatInfo.seatLayout
        const maxRow = Math.max(...seatLayout.map(seat => Number(seat.LocationRow)))
        const maxCol = Math.max(...seatLayout.map(seat => Number(seat.LocationCol)))

        const newSeatMatrix = createSeatMatrix(seatLayout, maxRow, maxCol, couponSeatList, Object.entries(sortedSeatStatus))

        if (JSON.stringify(newSeatMatrix) !== JSON.stringify(seatMatrix)) {
          setSeatMatrix(newSeatMatrix)
        }
      } catch (error) {
        console.error('Error fetching seat list:', error)
      }
    }
  }

  const createSeatMatrix = (seatList, maxRow, maxCol, couponSeatList, seatStatus) => {
    const matrix = Array.from({length: maxRow}, () => Array(maxCol).fill(null))
    const holdList = list.find(r => r.id === formData?.schedule?.id)?.schedule?.seatHoldList

    const couponSeatSet = new Set(couponSeatList)
    const isCouponSeat = (seat) => couponSeatSet.has(`${seat?.SeatGroup}${seat?.SeatNo}`)

    const status = (seatCode) => {
      if (holdList?.length > 0) {
        return holdList.includes(seatCode)
      }
    }

    // 좌석 데이터를 배열에 배치
    seatList.forEach((seat, idx) => {
      const row = Number(seat.LocationRow) - 1
      const col = Number(seat.LocationCol) - 1

      matrix[row][col] = {
        ...seat,
        SeatStatus: (seat.SeatGroup + seat.SeatNo === seatStatus[idx][0]) && status(seatStatus[idx][0]) ? 'HOLD' : seatStatus[idx][1],
        isCouponSeat: isCouponSeat(seat)
      }
    })

    return matrix
  }

  const handleInputChange = (event, idx) => {
    const targets = Array.isArray(event.target) ? event.target : [event.target]

    setFormData((prev) => {
      const updatedFormData = {...prev}

      targets.forEach(({name, value, id}) => {
        let updatedValue

        if (name === 'couponCode') {
          let newCouponCode = Array.isArray(prev.couponCode) ? [...prev.couponCode] : []

          if (id !== 'selectPackage') {
            newCouponCode[idx] = {
              id: null,
              code: value.toUpperCase(),
              status: utils.hasKoreanText(value) ? utils.COUPON_CHECK_TYPE.ENG : utils.COUPON_CHECK_TYPE.NONE,
              basicPackage: null
            }
          } else {
            newCouponCode[idx] = {
              ...newCouponCode[idx],
              basicPackage: value
            }
          }

          updatedValue = newCouponCode
        } else if (name === 'schedule') {
          updatedValue = JSON.parse(value)
        } else {
          updatedValue = value
        }

        updatedFormData[name] = updatedValue
      })

      return updatedFormData
    })
  }

  // 제출
  const handleSubmit = async (event) => {
    event.preventDefault()
    await submitCoupon()
  }

  const submitCoupon = async () => {
    if (!formData?.schedule.id) {
      return alert('선택된 영화가 없습니다.')
    }

    const {name, gender, couponCode, phoneNumber, schedule: {movie: {title}, movieTime: {scheduleStart}}} = formData

    if (utils.hasDuplicateByKey(couponCode, 'code')) {
      return alert('동일한 쿠폰은 입력할 수 없습니다.')
    }

    // 현재 시간이 예매 시간을 경과한 경우
    if (!utils.isTimeBefore(formData?.schedule, pathname)) {
      alert('현재 시간이 예매 가능 시간을 지나 제출할 수 없습니다.')
      return window.location.reload()
    }

    // 등록된 쿠폰 아닐 때
    if (!couponCode.every(r => r.status === utils.COUPON_CHECK_TYPE.SUCCESS)) {
      return alert('제출이 정상적으로 되지 않았습니다.')
    }

    const payload = (idx) => ({
      Code: couponCode[idx].code,
      Gender: gender,
      Name: name,
      Contact: phoneNumber,
      Movie: title,
      Package: couponCode[idx].basicPackage,
      Date: utils.formatDateTime(scheduleStart),
      Seat: ''
    })

    // 사용된 쿠폰 used 체크
    const patchCouponUsedCheck = async (idx) => {
      try {
        await table.usedCouponCheck(couponCode[idx], couponCode[idx].id)
      } catch (error) {
        console.error('Error updating coupon:', error)
        throw new Error('쿠폰 업데이트 실패')
      }
    }

    // payload 로그 출력
    const promises = couponCode.map(async (_, idx) => {
      const data = payload(idx)
      try {
        // patchCouponUsedCheck와 table.postCoupon 작업을 차례로 처리
        await patchCouponUsedCheck(idx) // 먼저 쿠폰 사용 여부 확인
        await table.postCoupon(data) // 그 후 쿠폰 제출
        return data // 성공적인 경우에만 데이터를 반환
      } catch (error) {
        console.error(`Error processing coupon ${couponCode[idx].code}:`, error)
        throw new Error(`쿠폰 처리 실패: ${couponCode[idx].code}`) // 오류 발생 시 전체 작업이 실패하도록
      }
    })

    try {
      utils.preventScroll()
      setSubmitLoading(true)

      // Promise.all에서 모든 비동기 작업이 완료될 때까지 기다리기
      const dataArray = await Promise.all(promises)

      // Slack 알림 전송
      try {
        await slack.postNotify(dataArray)
        alert('제출이 완료되었습니다.')
        window.location.reload()
      } catch (slackError) {
        console.error('Slack 알림 실패:', slackError)
        alert('제출은 완료되었으나 Slack 알림 전송에 실패했습니다. 관리자에게 문의해주세요.')
      }
    } catch (error) {
      console.error('Error 발생:', error)
      alert('제출 중 오류가 발생했습니다. 모든 쿠폰을 정상적으로 처리하지 못했습니다.')
    } finally {
      utils.allowScroll()
      setSubmitLoading(false)
    }
  }


  useEffect(() => {
    setSeatMatrix([])
    getListApi()
  }, [formData?.date])

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      try {
        // usedCouponSeatList 먼저 실행
        const couponSeatList = await getUsedCouponSeatListApi()

        // 그 다음에 getSeatListApi 실행
        await getSeatListApi(couponSeatList)
      } catch (error) {
        console.error('Error fetching seat lists:', error)
      } finally {
        setLoading(false)
      }
    }

    fetchData().catch(console.error)  // Promise rejection 처리
  }, [formData?.schedule.id])

  return (
    <>
      <Helmet>
        <title>MONOPLEX @RYSE Hongdae 관람권 쿠폰 등록</title>
        <meta name="description" content="MONOPLEX @RYSE Hongdae 관람권 쿠폰 등록"/>
        <meta property="og:title" content="MONOPLEX @RYSE Hongdae 관람권 쿠폰 등록"/>
        <meta property="og:description" content="MONOPLEX @RYSE Hongdae 관람권 쿠폰 등록"/>
      </Helmet>

      <main className="relative mt-4 bg-gray-200">
        <div className="xl:w-[840px] w-full m-auto h-full rounded pb-[70px]">
          <Header/>

          <form onSubmit={handleSubmit} className="mt-6 h-full">
            <Gender handleInputChange={handleInputChange}/>

            <Name handleInputChange={handleInputChange}/>

            <Contact formData={formData}
                     handleInputChange={handleInputChange}/>

            <Coupon formData={formData}
                    setFormData={setFormData}
                    handleInputChange={handleInputChange}
            />

            <ScheduleTable formData={formData}
                           setFormData={setFormData}
                           list={list}
                           handleInputChange={handleInputChange}/>

            {formData?.schedule.id &&
              <>
                <SelectMovie formData={formData}/>
                <Seat loading={loading}
                      list={list.filter(({schedule}) => formData?.schedule?.movieTime.scheduleDate === schedule.movieTime.scheduleDate && formData?.schedule?.movieTime.scheduleStart === schedule.movieTime.scheduleStart)}
                      seatMatrix={seatMatrix}
                />
              </>
            }

            <SubmitButton formData={formData}
                          submitLoading={submitLoading}
            />
          </form>

        </div>

        <Footer/>

        {submitLoading &&
          <div className={'fixed left-0 top-0 bottom-0 right-0 bg-black/50 z-10 flex items-center justify-center'}>
            <SpinLoading width={'w-8'} height={'h-8'} textColor={'text-white'}/>
          </div>
        }
      </main>
    </>
  )
}