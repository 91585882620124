import React from 'react'
import { utils } from '../../../utils/utils'

export const SubmitButton = ({submitLoading, formData }) => {
  const check = formData?.name &&
    formData?.phoneNumber &&
    formData?.gender &&
    formData?.couponCode?.length &&
    formData?.couponCode?.every(r => !!r.basicPackage) &&
    formData?.couponCode?.every(r => r.status === utils.COUPON_CHECK_TYPE.SUCCESS)

  return (
    <div className="mt-7 flex flex-col justify-between items-center">
      <button
        type={'submit'}
        className="rounded-md bg-black hover:bg-gray-800 px-7 py-3 text-md font-medium text-white transition focus:outline-none focus:ring-0 shadow-none disabled:bg-gray-400"
        disabled={!check || submitLoading || Object.keys(formData.schedule).length === 0}
      >
        제출
      </button>
    </div>
  )
}