import ajax from './ajax'

const ryseCd = 'm019'

const showApiEndPoint = '/show/v1'
const bookingApiEndPoint = '/booking/v1'

const show = {
  listByShowing({theaterCode, onShow}) {
    const payload = {theaterCode}
    if(onShow !== null) payload.onShow = onShow
    return ajax.get(`${showApiEndPoint}/schedule/showing-now?${new URLSearchParams(payload)}`)
  },
  reserveAvailableSchedule(payload) {
    return ajax.get(`${showApiEndPoint}/reservation/reserve-avail-schedules?${new URLSearchParams(payload)}`)
  },
  reserveAvailableDate(payload) {
    return ajax.get(`${showApiEndPoint}/reservation/reserve-avail-date?${new URLSearchParams(payload)}`)
  }
}

const booking = {
  list(date) {
    const payload = {
      theaterCode: ryseCd,
      from: date,
      to: date,
    }

    return ajax.get(`${bookingApiEndPoint}/schedule?${new URLSearchParams(payload)}`)
  },
  ticketListByScheduleId(id) {
    return ajax.get(`${bookingApiEndPoint}/schedule/${id}/ticket-list`)
  },
  orderListWithSearch( search) {
    return ajax.post(bookingApiEndPoint + '/orders/products', search, null)
  },
  getSeats(theaterCode, screenCode = '01') {
    return ajax.get(`${bookingApiEndPoint}/seats/${theaterCode}/${screenCode}`)
  },
  getSeatsStatus(id) {
    return ajax.get(`${bookingApiEndPoint}/reservation/${id}/seats/status??onoffline=ONLINE`)
  }
}

export default {show, booking}
