import { format } from 'date-fns'
import ko from 'date-fns/locale/ko'
import StyledContainer from '../../../../components/StyledContainer'
import React from 'react'
import { utils } from '../../../../utils/utils'

export const SelectMovie = ({formData}) => {
  const formatImg = (src) => {
    if(!src.includes('https')) {
      return process.env.REACT_APP_FILE_URL_DEV_PUBLIC + src
    }
    return src
  }

  return (
    <StyledContainer>
      <div className="text-sm font-medium text-gray-700">
        선택한 영화
      </div>

      <div className={'mt-3 flex items-center gap-4 h-full'}>
        <div className={'relative rounded bg-gray-500 w-20 h-28 shrink-0'}>
           <span
             className="absolute left-1.5 top-1.5 z-10"
           >
            <img src={utils.getRatingSvgUrl(formData?.schedule?.movie.rating)} alt={`${utils.renderScheduleRatingType(formData?.schedule?.movie.rating)}`}
                 className={'w-4 h-4 block'}/>
          </span>
          <img src={formatImg(formData.imgUrl)} alt={'poster'} className={'w-full h-full rounded'}/>
        </div>
        <div className={'shrink'}>
          <p className={'font-medium text-sm max-w-[120px] overflow-hidden text-ellipsis whitespace-nowrap'}>{formData?.schedule?.movie.title}
          </p>
          <p className={'mt-1 text-xs text-gray-700'}>
            {format(new Date(formData?.schedule?.movieTime.scheduleStart), 'yyyy.MM.dd(eee) HH:mm', {locale: ko})}
          </p>
          <p className={'mt-1 text-xs text-gray-700'}>
            모노플렉스 @라이즈 홍대
          </p>
          <p className={'mt-1 text-xs text-gray-700'}>
            {formData.schedule.screenName} • {utils.showTypeCodeToLabel(formData.schedule.movie.showType)}({utils.showTypeFormatCodeToLabel(formData.schedule.movie.showTypeFormat)})
          </p>
        </div>
      </div>
    </StyledContainer>
  )
}